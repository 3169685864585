import auth, { AuthParams } from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';

export type Route = AuthParams & {
  name: string;
  key: string;
  breadcrumb?: boolean;
  children?: Route[];
};

export const routes: Route[] = [
  {
    name: '数据统计',
    key: 'statistics',
    children: [
      {
        name: '用户点赞',
        key: 'statistics/dig',
      },
      {
        name: '用户关注',
        key: 'statistics/favorite',
      },
      {
        name: '文章查看',
        key: 'statistics/view',
      },
    
      {
        name: '用户点击',
        key: 'statistics/click',
      },
      {
        name: '用户活跃度',
        key: 'statistics/active',
      },
      {
        name: 'app启动日志',
        key: 'statistics/activelist',
      },
    ],
    requiredPermissions: [{ resource: 'admin', actions: ['*'] }],
  },
  {
    name: '文章管理',
    key: 'post',
    children: [
      {
        name: '一审队列',
        key: 'post/firstaudit',
        requiredPermissions: [
          { resource: 'admin', actions: ['*'] },
          { resource: 'auditor_first', actions: ['read'] },
        ],
        oneOfPerm: true,
      },
      {
        name: '二审队列',
        key: 'post/secondaudit',
        requiredPermissions: [
          { resource: 'admin', actions: ['*'] },
          { resource: 'auditor_second', actions: ['read'] },
        ],
        oneOfPerm: true,
      },
      {
        name: '复审队列',
        key: 'post/review',
        requiredPermissions: [
          { resource: 'admin', actions: ['*'] },
          { resource: 'auditor_third', actions: ['read'] },
        ],
        oneOfPerm: true,
      },
      {
        name: '文章列表',
        key: 'post/search',
        requiredPermissions: [{ resource: 'admin', actions: ['*'] }],
      },
      {
        name: '文章浏览',
        key: 'post/list',
        requiredPermissions: [{ resource: 'admin', actions: ['*'] }],
      },
      {
        name: '审核统计',
        key: 'post/audit',
        requiredPermissions: [
          { resource: 'admin', actions: ['*'] },
          { resource: 'auditor_first', actions: ['read'] },
          { resource: 'auditor_second', actions: ['read'] },
          { resource: 'auditor_third', actions: ['read'] },
        ],
        oneOfPerm: true,
      },
      {
        name: '评论审核',
        key: 'post/cmexamine',
        requiredPermissions: [
          { resource: 'admin', actions: ['*'] },
          { resource: 'auditor_comment', actions: ['read'] },
        ],
        oneOfPerm: true,
      },
      {
        name: '评论审核记录',
        key: 'post/cmaudit',
        requiredPermissions: [
          { resource: 'admin', actions: ['*'] },
          { resource: 'auditor_comment', actions: ['read'] },
        ],
        oneOfPerm: true,
      },
      {
        name: '发文审核',
        key: 'post/postaudit',
        requiredPermissions: [
          { resource: 'admin', actions: ['*'] },
          { resource: 'auditor_category', actions: ['read'] },
        ],
        oneOfPerm: true,
      },
      {
        name: '发文审核记录',
        key: 'post/postaduitlist',
        requiredPermissions: [
          { resource: 'admin', actions: ['*'] },
          { resource: 'auditor_category', actions: ['read'] },
        ],
        oneOfPerm: true,
      },
    ],
  },
  {
    name: '专栏管理',
    key: 'category',
    children: [
      {
        name: '类别管理',
        key: 'category/mainCategory',
        requiredPermissions: [
          { resource: 'admin', actions: ['*'] },
          { resource: 'auditor_third', actions: ['read'] },
        ],
        oneOfPerm: true,
      },
      {
        name: '专栏预览',
        key: 'category/browse',
        requiredPermissions: [
          { resource: 'admin', actions: ['*'] },
          { resource: 'auditor_third', actions: ['read'] },
        ],
        oneOfPerm: true,
      },
    ],
    requiredPermissions: [{ resource: 'admin', actions: ['*'] }],
  },
  {
    name: '反馈管理',
    key: 'feedback',
    children: [
      {
        name: '文章反馈',
        key: 'feedback/post',
      },
      {
        name: '评论反馈',
        key: 'feedback/comment',
      },
      {
        name: '用户反馈',
        key: 'feedback/user',
      },
      {
        name: '意见反馈',
        key: 'feedback/suggestion',
      },
    ],
    requiredPermissions: [{ resource: 'admin', actions: ['*'] }],
  },
  {
    name: '评论管理',
    key: 'comment',
    children: [
      {
        name: '评论列表',
        key: 'comment/list',
      },
      {
        name: '审核记录',
        key: 'comment/audit',
      },
    ],
    requiredPermissions: [{ resource: 'admin', actions: ['*'] }],
  },
  {
    name: '用户管理',
    key: 'user',
    children: [
      {
        name: '用户列表',
        key: 'user/list',
      },
      {
        name: '信息审核',
        key: 'user/audit',
      },
      {
        name: '操作分析',
        key: 'user/ratelist',
      },
      {
        name: '继承攒点比',
        key: 'user/extendratelist',
      },
      {
        name: '认证管理',
        key: 'user/auth',
      },
    ],
    requiredPermissions: [{ resource: 'admin', actions: ['*'] }],
  },
  {
    name: '积分管理',
    key: 'points',
    children: [
      {
        name: '积分管理',
        key: 'points/list',
      },
      {
        name: '操作日志',
        key: 'points/pointslogs',
      },
      {
        name: '专栏记录',
        key: 'points/categorylogs',
      },
      {
        name: '用户记录',
        key: 'points/userlogs',
      },
      {
        name: '提现记录',
        key: 'points/withdraw',
      },
    ],
    requiredPermissions: [{ resource: 'admin', actions: ['*'] }],
  },
  {
    name: '开关管理',
    key: 'switch',
    children: [
      {
        name: '开关列表',
        key: 'switch/list',
      },
    ],
    requiredPermissions: [{ resource: 'admin', actions: ['*'] }],
  },
  {
    name: '榜单管理',
    key: 'ranking',
    children: [
      {
        name: '榜单预览',
        key: 'ranking/list',
      },
    ],
    requiredPermissions: [{ resource: 'admin', actions: ['*'] }],
  },

  {
    name: '订阅管理',
    key: 'groups',
    children: [
      {
        name: '订阅列表',
        key: 'groups/list',
        requiredPermissions: [
          { resource: 'admin', actions: ['*'] },
          { resource: 'auditor_third', actions: ['read'] },
        ],
        oneOfPerm: true,
      },
    ],
    requiredPermissions: [{ resource: 'admin', actions: ['*'] }],
  },
  {
    name: '权限管理',
    key: 'admin',
    children: [
      {
        name: '管理员',
        key: 'admin/index',
      },
    ],
    requiredPermissions: [{ resource: 'admin', actions: ['*'] }],
  },
  {
    name: '机器人管理',
    key: 'robot',
    children: [
      {
        name: '机器人列表',
        key: 'robot/list',
      },
      {
        name: '发文列表',
        key: 'robot/post',
      },

      {
        name: '评论列表',
        key: 'robot/comment',
      },
      {
        name: '任务列表',
        key: 'robot/task',
      },

      {
        name: '抓取文章',
        key: 'robot/contentpool',
      },
      {
        name: '专栏统计',
        key: 'robot/info',
      },
      {
        name: '公众号订阅',
        key: 'robot/subscribe',
      },
      {
        name: '提示词管理',
        key: 'robot/prompt',
      },
      {
        name: 'api使用情况',
        key: 'robot/apiusage',
      },
    ],
    requiredPermissions: [{ resource: 'admin', actions: ['*'] }],
  },
  {
    name: '运营中心',
    key: 'operation',
    children: [
      {
        name: '内容提交',
        key: 'operation/creation',
      },
      {
        name: '机器人管理',
        key: 'operation/robots',
        requiredPermissions: [{ resource: 'admin', actions: ['*'] }],
      },
      {
        name: '发文统计',
        key: 'operation/articles',
      },
    ],
    requiredPermissions: [
      { resource: 'post_operator', actions: ['*'] },
      { resource: 'admin', actions: ['*'] },
    ],
    oneOfPerm: true,
  },
  {
    name: '工具栏',
    key: 'tools',
    children: [
      {
        name: '图片剪切',
        key: 'tools/imagecut',
      },
      {
        name: '百度统计',
        key: 'tools/statistics',
      },
    ],
    requiredPermissions: [{ resource: 'admin', actions: ['*'] }],
  },
];

export const getName = (path: string, routes) => {
  return routes.find((item) => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};

export const generatePermission = (role: string) => {
  const actions = role === 'admin' ? ['*'] : ['read'];
  const result = {};
  routes.forEach((item) => {
    if (item.children) {
      item.children.forEach((child) => {
        result[child.name] = actions;
      });
    }
  });
  return result;
};

const useRoute = (userPermission): [Route[], string] => {
  const filterRoute = (routes: Route[], arr = []): Route[] => {
    if (!routes.length) {
      return [];
    }
    for (const route of routes) {
      const { requiredPermissions, oneOfPerm } = route;
      let visible = true;
      if (requiredPermissions) {
        visible = auth({ requiredPermissions, oneOfPerm }, userPermission);
      }
      if (!visible) {
        continue;
      }
      if (route.children && route.children.length) {
        const newRoute = { ...route, children: [] };
        filterRoute(route.children, newRoute.children);
        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({ ...route });
      }
    }
    return arr;
  };

  const [permissionRoute, setPermissionRoute] = useState(filterRoute(routes));

  useEffect(() => {
    const newRoutes = filterRoute(routes);
    setPermissionRoute(newRoutes);
  }, [userPermission]);

  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];
    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }
    return '';
  }, [permissionRoute]);

  return [permissionRoute, defaultRoute];
};

export default useRoute;
